import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

const App = lazy(() => import("./App.js"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<div />}>
    <React.StrictMode>
      <App maintenance />
    </React.StrictMode>
  </Suspense>
);
